// src/pages/CVPage.js
import React from 'react';

function Home() {
  return (
    <div id="home-body" className="flex flex-col w-full text-white">
        <div className="flex w-full h-screen">
{/*         <img  className="w-48" src="./noun-blockchain_v2.svg" width={1} alt="home-icon"></img> */}

        <h1 className=" text-5xl md:text-8xl lg:text-[250px] hover:text-gray-200 font-micro pl-10 md:pl-20 pt-24">Emmanuel Martínez</h1>
        </div>
    </div>   
  );
}

export default Home;