import React, { useState, useEffect } from 'react';

const CustomCursor = () => {
  
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [stopped, setStopped] = useState(false);

  useEffect(() => {
    let moveTimer = null;

    const updatePosition = (e) => {
      if (stopped) setStopped(false); // Reset the stopped state when the cursor moves
      setHidden(false); // Show the cursor when it moves
      setPosition({ x: e.clientX, y: e.clientY });

      clearTimeout(moveTimer);

      // Set the cursor to "stopped" after 2 seconds of inactivity
      moveTimer = setTimeout(() => {
        setStopped(true);
      }, 100);
    };

    window.addEventListener('mousemove', updatePosition);

    return () => {
      window.removeEventListener('mousemove', updatePosition);
      clearTimeout(moveTimer);
    };
  }, [stopped]);

  return (
    <div
      className={`cursor hidden lg:block ${stopped ? 'cursor-stopped' : ''} border-[1px] border-white`}
      style={{
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        transition: 'width 0.5s ease, height 0.5s ease', // Smooth transition for size changes
        // Customize your cursor's appearance:
        width: stopped ? '30px' : '15px', // Slightly increase size when stopped
        height: stopped ? '30px' : '15px',
        borderRadius: '50%',

        pointerEvents: 'none', // Ensures the cursor doesn't interfere with other elements
        opacity: hidden ? 0 : 1, // Hide cursor when not moving
      }}
    />
  );
};

export default CustomCursor;
