import React from 'react';

function ReverseIcon() {
    return (
      <div className="text-white hover:text-gray-400">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.67 76">
          <title>noun-rewind-1571398</title>
          <path d="M24.35,36.62a2.62,2.62,0,0,1,.1-.73,2,2,0,0,1,1-1.3l3.69-2.15V24a3.28,3.28,0,0,0-1.18-2.27,1.14,1.14,0,0,0-.58-.29h0L14,29.17a2.07,2.07,0,0,0,.36,1.72h0l13.36,23.2c.53.91,1.18,1.2,1.36,1.18a1.32,1.32,0,0,0,0-.34V46.14L25,39.06A4.88,4.88,0,0,1,24.35,36.62Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path d="M39.77,28.57h0L26.41,36.33a2.07,2.07,0,0,0,.36,1.72l.79,1.37L40.13,61.25c.53.91,1.16,1.22,1.36,1.19a1.39,1.39,0,0,0,0-.35v-31a3.24,3.24,0,0,0-1.18-2.27A1.07,1.07,0,0,0,39.77,28.57Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path d="M35.49,5.67C29.05,1.94,23,1,18.29,3c.51,0,1,.07,1.55.13l.14,0c.57.07,1.14.17,1.72.29l.46.1c.54.13,1.09.27,1.63.44l.3.08q.94.3,1.92.69c.15,0,.3.12.44.18.51.21,1,.44,1.54.69l.55.26c.65.33,1.3.67,2,1C41.84,13.47,52,27.07,56.43,41.57a48.53,48.53,0,0,1,2.22,13.89c0,.85,0,1.66-.09,2.46l-.06.7c-.06.59-.12,1.16-.21,1.72,0,.22-.06.45-.1.67-.12.73-.27,1.46-.44,2.15h0c-.18.7-.39,1.36-.62,2-.07.19-.14.37-.22.55-.18.48-.38.93-.59,1.38-.09.17-.17.35-.25.52-.3.57-.62,1.12-1,1.66h0c4.82-2.77,7.56-8.8,7.56-16.84C62.64,35.3,50.46,14.3,35.49,5.67Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path d="M54.51,42.15c-4.26-14-14.08-27.19-25-33.49-.61-.35-1.23-.68-1.83-1l-.56-.27c-.43-.2-.86-.4-1.29-.58l-.59-.24-1.4-.51L23.45,6c-.59-.18-1.16-.34-1.74-.47l-.42-.08c-.43-.09-.86-.17-1.29-.23l-.54-.06c-.4-.05-.8-.08-1.19-.09l-.48,0a10.86,10.86,0,0,0-1.14,0l-.54,0c-.54.06-1.08.14-1.61.25a13.08,13.08,0,0,0-2,.62h0l-.58.29a1.06,1.06,0,0,1-.25.09C6.37,8.87,3.36,15.09,3.36,23.54a41.19,41.19,0,0,0,.55,6.56C6.48,46,17.66,62.92,30.5,70.34c7.15,4.13,13.8,4.8,18.71,1.89l0,0h0c.32-.19.64-.39.94-.61s.58-.44.85-.68l.09-.08c3.61-3.21,5.52-8.53,5.52-15.38A46.3,46.3,0,0,0,54.51,42.15Zm-11,19.94A2.17,2.17,0,0,1,42,64.37a2.59,2.59,0,0,1-.55.07,3.89,3.89,0,0,1-3.08-2.19L31.12,49.61v5.32a2.14,2.14,0,0,1-1.5,2.27,2.08,2.08,0,0,1-.55.07A3.86,3.86,0,0,1,26,55.09L12.63,31.89a4.33,4.33,0,0,1-.6-3.16,2.08,2.08,0,0,1,1-1.3l13.34-7.76a2.26,2.26,0,0,1,2.35.13A5.2,5.2,0,0,1,31.12,24v7.32l7.64-4.45a2.3,2.3,0,0,1,2.35.13,5.21,5.21,0,0,1,2.42,4.17Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </svg>
      </div>
    );
}

export default ReverseIcon;
