import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import Home from './pages/Home';
import CV from './pages/CV';
import CustomCursor from './components/CustomCursor';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function App() {

  const printRef = React.useRef();

  const generatePDF = () => {
    html2canvas(printRef.current, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
  
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
  
      // Set the background color for each page
      const backgroundColor = '#04080F'; // Example: '#ff0000' for red
      pdf.setFillColor(backgroundColor);
      pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');
  
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
  
      const scale = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
      const imgWidth = canvasWidth * scale;
      const imgHeight = canvasHeight * scale;
  
      let heightLeft = imgHeight;
      let position = 0; // Adjust based on content positioning needs
  
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  
      heightLeft -= pdfHeight;
  
      while (heightLeft > 0) {
        pdf.addPage();
        
        // Set the background color for the new page
        pdf.setFillColor(backgroundColor);
        pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');
  
        position -= pdfHeight;
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }
  
      pdf.save("cv.pdf");
    });
  };
  
  
  return (
    <div ref={printRef} className="cv-content">
    <div className="App bg-[#04080F] h-fit cursor-crosshair">
    <CustomCursor />  
{/*     <button className="fixed text-white z-[99]" onClick={generatePDF}>Download CV as PDF</button>
 */}    <Router>
    <header className="App-header">
    <Navbar/>
    </header>   
       <Routes>
       <Route path="/" element={<Home />} />        
       <Route path="/cv" element={<CV />} />
      </Routes>
    </Router>
    </div>
    </div>
  );
}

export default App;
