import React from 'react';

function HomeIcon() {
  return (
    <div className="text-white hover:text-gray-400" >
      <svg style={{ fill: "currentColor" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.33 73.5">
        <title>home</title>
        <path d="M60.65,34.29a.59.59,0,0,0-.14.06l-9.22,5.33-.09,0-5.05,2.92L27.38,14.73l0-.05-2.79-4.16L41.9.5,63.51,32.62ZM19.9,7.63,33.2,3.22l-10.49,6-10.5,3.49-2.79.93ZM1.4,18.46l9.28-3.09,12-4,3,4.45a.1.1,0,0,0,0,.05l19.09,28.4v2.46l-.21-.12L28.75,37.45,4.82,23.63h0l-3.4-2Zm34,33.59L26,46.63A1,1,0,0,0,24.6,47a1.06,1.06,0,0,0-.13.5V61.93l-19.15-11V26.23l16.32,9.42L40.92,46.78V71.43l-5.05-2.91V52.92A1,1,0,0,0,35.37,52.05ZM60,41.45V61.57L42.92,71.44V47.94l2.42,1.39h0a1,1,0,0,0,1,0Zm3.93-4.59-17.1,9.88V44.55l5.28-3,.09,0,9.2-5.31.14-.07,2.39-1.38Z"/>
      </svg>
    </div>
  );
}

export default HomeIcon;
