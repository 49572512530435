// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '../img/HomeIcon';
import ReverseIcon from '../img/ReverseIcon';


function Navbar() {

  const [activeHomeIcon, setActiveHomeIcon] = useState(true);

  return (
    <nav className="w-full bg-[#04080F] h-20 text-white text-2xl font-silkscreen border-b-2 border-white pb-3 select-none fixed">
      <ul className="flex flex-row w-full justify-end items-end gap-3  pr-14 md:pr-20 h-full">

        <li className="w-6"
          onMouseEnter={() => setActiveHomeIcon(false)} 
          onMouseLeave={() => setActiveHomeIcon(true)}
        >

          <Link to="/">
              
              { activeHomeIcon ? <HomeIcon /> : <ReverseIcon />}

          </Link>
        </li>
        <li className="text-white text-lg lg:text-xl hover:text-gray-400">
          <Link to="/cv">CV</Link>
        </li>
        {/*  Add more navigation links as needed */}
      </ul>
    </nav>
  );
}

export default Navbar;
