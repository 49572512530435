import React, { useState } from "react";
import { GrLinkedin } from "react-icons/gr";
import { AiFillGithub } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import { IoMdMailOpen } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";


function CV() {

  const [activeMail, setActiveMail] = useState(false);
  const [verMas, setVerMas] = useState(false);
  const [lang, setLang] = useState("es");



  return (

    
    <div className="text-white px-14 md:px-20 pt-28 h-fit">
    
      <div className="pb-6">
        <div className={`flex flex-col md:flex-row gap-2 items-center space-y-2`}>

        <div className="w-full flex flex-row gap-2">
        <p className="text-5xl lg:text-5xl font-micro hidden md:block">
          { lang === "es" ? "Desarrollador Full Stack especializado en Web 3.0" : "Full Stack Developer specialized in Web 3.0"}
        </p>
        <p className={`${lang === "es" ? "text-gray-400" : "text-white"} font-micro text-2xl ml-auto hover:text-gray-400 cursor-pointer pt-0 md:pt-3 lg:pt-0`}
        onClick={() => { setLang("es") }}
        >ES</p>
        <p className="pt-0 md:pt-3 lg:pt-0"> | </p>
        <p className={`${lang === "en" ? "text-gray-400" : "text-white"} font-micro text-2xl hover:text-gray-400 cursor-pointer pt-0 md:pt-3 lg:pt-0`}
        onClick={() => { setLang("en") }}
        >EN</p>
        </div>

        <p className="text-3xl font-micro block md:hidden">
          { lang === "es" ? "Desarrollador Full Stack especializado en Web 3.0" : "Full Stack Developer specialized in Web 3.0"}
        </p>
        </div>
        <div className="flex items-center gap-2 pt-2 md:pt-0">
          <FaMapMarkerAlt />
          <p className="text-base lg:text-lg">CDMX, MX-CMX, 03100 |</p>
          <a
            href="mailto:helloworld@emmanuelmartinez.dev"
            target="_blank" 
            rel="noreferrer"
            className="flex items-center space-x-2 space-y-2 pt-1"
          >
            {activeMail ? (
              <IoMdMailOpen
                className="block text-xl text-gray-400"
                onMouseLeave={() => setActiveMail(false)}
              />
            ) : (
              <IoMail
                className=" text-xl block"
                onMouseEnter={() => setActiveMail(true)}
              />
            )}
          </a>
        </div>

        <div className="w-full flex gap-3 pt-2 ">
          <a href="https://mx.linkedin.com/in/emmanuel-vel%C3%A1zquez-martinez-a1497551"
             target="_blank" 
             rel="noreferrer"
          >
            <GrLinkedin className="text-white hover:text-gray-400 text-base lg:text-lg" />
          </a>
          <a href="https://github.com/emmanuelmartinezfreelancer"
          target="_blank" 
          rel="noreferrer"
          >
            <AiFillGithub className="text-white hover:text-gray-400 text-base lg:text-lg" />
          </a>
        </div>
      </div>
      
      { lang === "es" ? 

      <>
      <section className="pb-6">
        <h2 className="text-2xl font-jura mb-4">Perfil Profesional</h2>
        <p className="text-sm md:text-base">
          Con experiencia en tecnologías Web 3.0, me especializo en blockchain,
          realidad extendida (XR) e inteligencia artificial aplicados al desarrollo web. Con
          formación en Diseño y Comunicación Visual y conocimiento en
          programación imperativa, funcional y orientada a objetos, me enfoco en
          el desarrollo de soluciones avanzadas que integran comunicación y
          tecnología. Mi trabajo abarca desde la creación de DApps y smart
          contracts hasta el desarrollo de aplicaciones cloud-native y
          plataformas educativas en tecnología blockchain.
        </p>
      </section>

      <section className="mb-6">
        <div className="skills ">
          <h2 className="text-2xl font-jura pb-6">Habilidades Técnicas</h2>

          <div className="w-full grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-2 lg:gap-2  lg:gap-y- lg:grid-cols-3">
            <div className="font-bold skill-set pb-6 text-sm lg:text-base">
              <h3 className="font-bold text-base lg:text-lg">Desarrollo Web Avanzado</h3>
              <p>
                <i class="icon-js-square "></i>{" "}
                <span className="font-light">Lenguajes de programación: </span>
                {" "}JavaScript, TypeScript, PHP
              </p>
              <p>
                <i class="icon-react"></i>{" "}
                <span className="font-light">Frameworks: </span> React,{" "}
                <i class="icon-css3-alt"></i> TailwindCSS,{" "}
                <i class="icon-next-dot-js"></i> Next.js, Express
              </p>
              <p>
                <i class="icon-node-js"></i>{" "}
                <span className="font-light"> Entorno de ejecución: </span>
                NodeJS{" "}
              </p>
              <p>
                <i class="icon-database"></i>{" "}
                <span className="font-light">Bases de datos:</span> MongoDB,
                Firebase Firestore, Supabase (PostgreSQL)
              </p>
            </div>

            <div className="font-semibold skill-set pb-6 text-sm lg:text-base">
              <h3 className="font-bold text-base lg:text-lg">
                Blockchain y Tecnologías Descentralizadas
              </h3>
              <p>
                <i class="icon-code"></i>{" "}
                <span className="font-light">
                  Lenguajes de programación para Smart Contracts:
                </span>{" "}
                Solidity, Archetype, SmartPy
              </p>
              <p>
          <i class="icon-flask"></i>
          <span className="font-light">Frameworks:</span> Truffle Suite, Octez Suite
        </p>
        <p>
          <i class="icon-flask"></i>
          <span className="font-light">Librerías:</span> web3.js, taquito.ts
        </p>
        <p>
          <i class="icon-flask"></i>
          <span className="font-light">CLI:</span> completium-cli
        </p>
              <p>
                <i class="icon-ethereum"></i>{" "}
                <span className="font-light">Plataformas:</span> Ethereum,
                Polygon, Tezos
              </p>
              <p>
                <span className="font-light">
                  Almacenamiento descentralizado:{" "}
                </span>
                IPFS
              </p>
            </div>

            <div className="font-semibold skill-set pb-6 text-sm lg:text-base">
              <h3 className="font-bold text-base lg:text-lg">
                Integración de Inteligencia Artificial
              </h3>
              <p>
                <span className="font-light">Lenguaje de Programación:</span>{" "}
                Python
              </p>
              <p>
                <i class="icon-flask"></i>
                <span className="font-light">Framework:</span> Flask
              </p>
              <p>
                <i class="icon-robot"></i>Desarrollo de API serverless con
                modelos preentrenados de IA
              </p>
              <p>
                <i class="icon-google"></i> Uso de Notebooks Jupyter con Google
                Colab
              </p>
              <p>Fundamentos de Machine Learning Supervisado y Deep Learning</p>
            </div>

            <div className="font-semibold skill-set text-sm lg:text-base pb-6">
              <h3 className="font-bold text-base lg:text-lg">X-Reality y Multimedia</h3>
              <p>
                {" "}
                <span className="font-light">
                  Lenguaje de Programación:
                </span>{" "}
                GLSL (Shaders)
              </p>
              <p>
                <i class="icon-film"></i>{" "}
                <span className="font-light">Bibliotecas: </span>THREE.js,
                a-frame.js, p5.js, tone.js, hydra-synth.js
              </p>
              <p>
                <i class="icon-film"></i> Adobe Suite (Photoshop, Illustrator,
                Premiere, After Effects)
              </p>
              <p>
                <span className="font-light">3D software:</span> Blender, Maya
              </p>
              <p>Spark AR</p>
              <p>
                <i class="icon-music"></i> Ableton Live
              </p>
            </div>

            <div className="font-semibold skill-set text-sm lg:text-base pb-6">
              <h3 className="font-bold text-base lg:text-lg">
                Arquitectura de Servicios y Diseño de APIs
              </h3>

              <p>
                <i class="icon-rest"></i> RESTful, GraphQL
              </p>
              <p>
                <i class="icon-microservice"></i> Microservicios
              </p>
              <p>
                <i class="icon-postman"></i>{" "}
                <span className="font-light">Test: </span>Postman, cURL
              </p>
            </div>

            <div className="font-semibold skill-set text-sm lg:text-base">
              <h3 className="font-bold text-base lg:text-lg">
                Soluciones en la Nube y DevOps
              </h3>

              <p>
                <i class="icon-cloud"></i> <span className="font-light">PaaS:</span> Vercel, Heroku
              </p>
              <p>
                <i class="icon-aws"></i> <span className="font-light"> BaaS: </span>Firebase{" "}
              </p>
              <p> <span className="font-light">IaaS: </span>AWS (Cloud Practitioner)</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-jura mb-4">Experiencia Profesional</h2>

        <div className="mb-4">
          <h3 className="font-bold">Escuela Superior de Arte y Tecnología</h3>
          <p className="italic">
            Desarrollador Full Stack y de DApps - Ene 2023 - Presente, Valencia,
            España
          </p>
          <ul className="list-square ml-5 font-light">
            <li>Desarrollo de DApps en Ethereum y Polygon.</li>
            <li>
              Implementación de infraestructura backend serverless en Firebase.
            </li>
            <li>Automatización y bots de Discord.</li>
            <li>Desarrollo de API para diplomas digitales.</li>
            <li>Liderazgo en proyectos educativos en blockchain.</li>
            <li>
              Creación de Documentación Técnica para infraestructura backend
              serverless y automatización de bots.
            </li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="font-bold">Solimán López Development Studio</h3>
          <p className="italic ">
            Desarrollador Full Stack y de DApps - Nov 2021 - Presente, Valencia,
            España
          </p>
          <ul className="list-square ml-5 font-light">
            <li>
              Desarrollo de aplicaciones web y API para proyectos artísticos y
              NFT.
            </li>
            <li>Manejo de smart contracts en múltiples blockchains.</li>
            <li>
              Pruebas y aseguramiento de calidad utilizando Truffle Suite y
              web3.js.
            </li>
            <li>
              Desarrollo de aplicaciones web dinámicas y entornos 3D utilizando
              React.js, GLSL, Express.js, Firebase y Cloud Functions.
            </li>
            <li>
              Diseño y desarrollo de soluciones en 3D con Three.js y
              React-Three-Fiber.
            </li>
            <li>
              Recientemente, desarrollo de un tema de WordPress personalizado
              para otorgar funcionalidades de conexión con la blockchain,
              utilizando PHP y JavaScript.
            </li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="font-bold">Neon Caron Studio</h3>
          <p className="italic">
            Productor General - Mayo 2017 - 2022, Ciudad de México
          </p>
          <ul className="list-square ml-5 font-light">

          <li>Diseño y desarrollo de prototipos para una colección de NFTs ERC721, utilizando Solidity para los Smart Contracts y un script de JavaScript para la generación automatizada de imágenes, combinando arte digital con blockchain.</li>
          <li>Mantenimiento y mejora de una aplicación móvil de Realidad Aumentada desarrollada en Swift, asegurando una experiencia de usuario fluida y actualizada.</li>
          <li>
              Gestión web y desarrollo de proyectos, incluyendo el mantenimiento
              del sitio web y desarrollo de soluciones web para Realidad
              Aumentada.
            </li>
            <li>
              Producción artística y técnica multidisciplinaria, combinando
              métodos tradicionales con tecnologías avanzadas como Realidad
              Aumentada.
            </li>
            <li>
              Creación de animación digital y tradicional para enriquecer las
              obras artísticas.
            </li>
            <li>
              Exhibición en ferias internacionales, colaborando con marcas como
              Mercedes Benz para integrar arte y tecnología de forma innovadora.
            </li>
            <li>
              Innovación en producción, utilizando métodos de producción
              avanzados como cortadoras CNC y robots dibujantes.
            </li>
            <li>
              Liderazgo y coordinación de proyectos, asegurando la superación de
              expectativas artísticas y técnicas.
            </li>
          </ul>
        </div>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-jura mb-4">
          Proyectos Destacados Freelance (2020 - Presente)
        </h2>

        {/* Proyectos iniciales visibles */}
        <div className="mb-4">
          <h3 className="font-bold">Lore Machine (Los Angeles, USA)</h3>
          <p className="font-light">
            Desarrollo Full Stack de una aplicación que utiliza IA generativa
            para la producción audiovisual, empleando Next.js, TypeScript y
            PostgreSQL.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="font-bold">custodia.place (Puebla, México)</h3>
          <p className="font-light">
            Desarrollo backend para una obra artística digital en blockchain,
            utilizando Solidity, Truffle y web3.js, con una interfaz creada en
            Handlebars.js.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="font-bold">Gym Buddies (New York, USA)</h3>
          <p className="font-light">
            Liderazgo en el desarrollo de una plataforma web para conectar la
            comunidad de gimnasios, implementando Firebase Firestore y React con
            Tailwind CSS.
          </p>
        </div>

            {/* Continúa agregando tus proyectos de la misma manera que los anteriores */}
            <div className="mb-4">
              <h3 className="font-bold">
                BARCO - Bienal de Arte Contemporáneo (Guanajuato, México)
              </h3>
              <p className="font-light">
                Desarrollo Full Stack de una plataforma digital para la bienal,
                incluyendo un tema de WordPress personalizado y una web app con
                React, Firestore y TailwindCSS.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="font-bold">Divine Nymphs (Miami, USA)</h3>
              <p className="font-light">
                Desarrollo de un contrato ERC-721 para NFTs, integrando realidad
                alternativa en blockchain con Solidity y Truffle Suite.
              </p>
            </div>

        {/* Botón para mostrar más proyectos */}
        {!verMas && (
          <button
            onClick={() => setVerMas(true)}
            className="px-4 py-2 border-2 border-white text-white hover:text-[#04080F] rounded hover:bg-white transition duration-300 w-full mt-2"
          >
            Mostrar más +
          </button>
        )}

        {/* Proyectos adicionales que se muestran al hacer clic en "Mostrar más +" */}
        {verMas && (
          <>


            <div className="mb-4">
              <h3 className="font-bold">hivemind DAO (Brasil)</h3>
              <p className="font-light">
                Desarrollé contratos inteligentes para fortalecer la comunidad
                de artistas y coleccionistas en el ecosistema Web3, promoviendo
                la creatividad y el comercio digital mediante Solidity y pruebas
                unitarias rigurosas.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                aestheticMachine.js (Ciudad de México, México)
              </h3>
              <p className="font-light">
                Lideré un proyecto artístico que fusiona arte digital y
                blockchain, permitiendo a los usuarios crear gráficos mediante
                p5.js y acuñar tokens ERC-721, destacando en eventos culturales
                importantes.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                BILIA - Bienal Latinoamericana de Inteligencia Artificial
                (Ciudad de México, México)
              </h3>
              <p className="font-light">
                Contribuí con una instalación que explora la IA y el arte,
                empleando técnicas de Machine Learning para generar
                visualizaciones conceptuales y fomentar el diálogo entre
                tecnología y expresión artística.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                Facultad de Artes y Diseño, UNAM (Ciudad de México, México)
              </h3>
              <p className="font-light">
                Desarrollé un metaverso como parte de un proyecto académico,
                ofreciendo una plataforma para la exposición de arte digital y
                promoviendo el debate sobre la percepción en contextos
                digitales.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">Cut Out Fest (Querétaro, México)</h3>
              <p className="font-light">
                Desarrollé una web app y un prototipo de metaverso para este
                festival internacional de animación y arte digital,
                implementando un entorno multiplayer en tiempo real y un sistema
                de NFTs basado en Solidity.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                Trámite, Buro de Coleccionistas - Refugio Digital (Querétaro,
                México)
              </h3>
              <p className="font-light">
                Colaboré en la creación de un metaverso único, transformando la
                interacción digital en una experiencia tangible mediante la
                programación de hardware y el desarrollo 3D.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                Netflix Latinoamérica (Ciudad de México, México)
              </h3>
              <p className="font-light">
                Contribuí a la creación de experiencias visuales inmersivas
                mediante video panoramas 360, utilizando After Effects y visores
                de realidad virtual HTC para optimizar la visualización y
                asegurar una integración impecable de las imágenes.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                Sony Music - Filtro de Realidad Aumentada (Ciudad de México,
                México)
              </h3>
              <p className="font-light">
                Desarrollé un filtro de realidad aumentada para Instagram con
                Spark AR y Maya, logrando un éxito significativo en su
                lanzamiento y demostrando el impacto visual y la capacidad de
                involucrar a la audiencia.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                ZeYX Lab (Ciudad de México - Querétaro, México)
              </h3>
              <p className="font-light">
                En ZeYX Lab, un observatorio de arte y tecnología digital,
                lideré proyectos que exploran la fusión de arte, música y
                tecnologías digitales, destacando:
              </p>
              <ul className="list-disc ml-5">
                <li className="font-light">
                  <strong>El Templo de Obsidiana:</strong> Creé un espacio
                  digital para narrativas hipermediales, integrando un bot de
                  Twitter que interactúa con los usuarios, fomentando un diálogo
                  interactivo y reflexiones colectivas.
                </li>
                <li className="font-light">
                  <strong>Outsiders Gallery:</strong> Desarrollé una exposición
                  digital utilizando THREE.js para presentar obras de artistas
                  digitales mexicanos, ofreciendo una experiencia inmersiva y
                  vanguardista.
                </li>
                <li className="font-light">
                  <strong>Galería Libertad - "Cristales Anamórficos":</strong>{" "}
                  Participé en un evento de arte digital en vivo, utilizando
                  hydra-synth.js para crear visuales anamórficos en tiempo real,
                  ampliando las posibilidades del arte visual y la interacción
                  artística.
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">
                Otoño en Hiroshima (Ciudad de México, México)
              </h3>
              <p className="font-light">
                Desarrollé una experiencia interactiva para el EP "Alquitrán" de
                Jardines, utilizando THREE.js para crear un entorno virtual que
                fusiona música y visuales, transformando la forma en que se
                experimenta la música digitalmente y fortaleciendo la conexión
                entre artistas y audiencia.
              </p>
            </div>
          </>
        )}
      </section>

      <section className="pb-20">
        <h2 className="text-2xl font-jura mb-4">Educación</h2>
        <div>
          <p>
            Licenciatura en Diseño y Comunicación Visual (UNAM) y cursos
            especializados en Cloud, Data, Deep Learning y Blockchain, subrayando un compromiso
            continuo con el aprendizaje y la innovación tecnológica.
          </p>
        </div>
        {/* Add more education as needed */}
      </section>
      </>

      :
      <>
      <section className="pb-6">
  <h2 className="text-2xl font-jura mb-4">Professional Profile</h2>
  <p className="text-sm md:text-base">
    With experience in Web 3.0 technologies, I specialize in blockchain, extended reality (XR) , and artificial intelligence applied to web development. With a background in Design and Visual Communication and knowledge in imperative, functional, and object-oriented programming, I focus on developing advanced solutions that integrate communication and technology. My work spans from creating DApps and smart contracts to developing cloud-native applications and educational platforms in blockchain technology.
  </p>
</section>

<section className="mb-6">
  <div className="skills ">
    <h2 className="text-2xl font-jura pb-6">Technical Skills</h2>

    <div className="w-full grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-2 lg:grid-cols-3">
      <div className="font-semibold skill-set pb-6 text-sm lg:text-base">
        <h3 className="font-bold text-base lg:text-lg">Advanced Web Development</h3>
        <p>
          <i class="icon-js-square"></i> 
          <span className="font-light">Programming languages: </span>
          JavaScript, TypeScript, PHP
        </p>
        <p>
          <i class="icon-react"></i> 
          <span className="font-light">Frameworks:</span> React, 
          <i class="icon-css3-alt"></i> TailwindCSS, 
          <i class="icon-next-dot-js"></i> Next.js, Express
        </p>
        <p>
          <i class="icon-node-js"></i> 
          <span className="font-light"> Runtime environment: </span>
          NodeJS 
        </p>
        <p>
          <i class="icon-database"></i> 
          <span className="font-light">Databases:</span> MongoDB,
          Firebase Firestore, Supabase (PostgreSQL)
        </p>
      </div>

      <div className="font-semibold skill-set pb-6 text-sm lg:text-base">
        <h3 className="font-bold text-base lg:text-lg">
          Blockchain and Decentralized Technologies
        </h3>
        <p>
          <i class="icon-code"></i> 
          <span className="font-light">
            Programming languages for Smart Contracts:
          </span> 
          Solidity, Archetype, SmartPy
        </p>
        <p>
          <i class="icon-flask"></i>
          <span className="font-light">Framework:</span> Truffle Suite, Octez Suite
        </p>
        <p>
          <i class="icon-flask"></i>
          <span className="font-light">Library:</span> web3.js, taquito.ts
        </p>
        <p>
          <i class="icon-flask"></i>
          <span className="font-light">CLI:</span> completium-cli
        </p>
        <p>
          <i class="icon-ethereum"></i> 
          <span className="font-light">Platforms:</span> Ethereum,
          Polygon, Tezos
        </p>
        <p>
          <span className="font-light">
            Decentralized storage: 
          </span>
          IPFS
        </p>
      </div>

      <div className="font-semibold skill-set pb-6 text-sm lg:text-base">
        <h3 className="font-bold text-base lg:text-lg">
          Integration of Artificial Intelligence
        </h3>
        <p>
          <span className="font-light">Programming Language:</span> 
          Python
        </p>
        <p>
          <i class="icon-flask"></i>
          <span className="font-light">Framework:</span> Flask
        </p>
        <p>
          <i class="icon-robot"></i>Development of serverless APIs with
          pretrained AI models
        </p>
        <p>
          <i class="icon-google"></i> Use of Jupyter Notebooks with Google
          Colab
        </p>
        <p>Foundations of Supervised Machine Learning and Deep Learning</p>
      </div>

      <div className="font-semibold skill-set text-sm lg:text-base pb-6">
        <h3 className="font-bold text-base lg:text-lg">X-Reality and Multimedia</h3>
        <p> 
          <span className="font-light">
            Programming Language:
          </span> 
          GLSL (Shaders)
        </p>
        <p>
          <i class="icon-film"></i> 
          <span className="font-light">Libraries: </span>THREE.js,
          a-frame.js, p5.js, tone.js, hydra-synth.js
        </p>
        <p>
          <i class="icon-film"></i> Adobe Suite (Photoshop, Illustrator,
          Premiere, After Effects)
        </p>
        <p>
          <span className="font-light">3D software:</span> Blender, Maya
        </p>
        <p>Spark AR</p>
        <p>
          <i class="icon-music"></i> Ableton Live
        </p>
      </div>

      <div className="font-semibold skill-set text-sm lg:text-base pb-6">
        <h3 className="font-bold text-base lg:text-lg">
          Services Architecture and API Design
        </h3>

        <p>
          <i class="icon-rest"></i> RESTful, GraphQL
        </p>
        <p>
          <i class="icon-microservice"></i> Microservices
        </p>
        <p>
          <i class="icon-postman"></i> 
          <span className="font-light">Test: </span>Postman, cURL
        </p>
      </div>

      <div className="font-semibold skill-set text-sm lg:text-base pb-6">
        <h3 className="font-bold text-base lg:text-lg">
          Cloud Solutions and DevOps
        </h3>

        <p>
          <i class="icon-cloud"></i> PaaS: Vercel, Heroku
        </p>
        <p>
          <i class="icon-aws"></i> BaaS: Firebase 
        </p>
        <p>IaaS: AWS (Cloud Practitioner)</p>
      </div>
    </div>
  </div>
</section>

<section className="mb-6">
  <h2 className="text-2xl font-jura mb-4">Professional Experience</h2>

  <div className="mb-4">
    <h3 className="font-bold">Escuela Superior de Arte y Tecnología</h3>
    <p className="italic">
      Full Stack and DApp Developer - Jan 2023 - Present, Valencia,
      Spain
    </p>
    <ul className="list-square ml-5 font-light">
      <li>Development of DApps on Ethereum and Polygon.</li>
      <li>
        Implementation of serverless backend infrastructure on Firebase.
      </li>
      <li>Automation and Discord bots.</li>
      <li>API development for digital diplomas.</li>
      <li>Leadership in educational projects on blockchain.</li>
      <li>
        Creation of Technical Documentation for serverless backend infrastructure and bot automation.
      </li>
    </ul>
  </div>

  <div className="mb-4">
    <h3 className="font-bold">Solimán López Development Studio</h3>
    <p className="italic ">
      Full Stack and DApp Developer - Nov 2021 - Present, Valencia,
      Spain
    </p>
    <ul className="list-square ml-5 font-light">
      <li>
        Development of web applications and APIs for artistic projects and
        NFTs.
      </li>
      <li>Management of smart contracts on multiple blockchains.</li>
      <li>
        Testing and quality assurance using Truffle Suite and
        web3.js.
      </li>
      <li>
        Development of dynamic web applications and 3D environments using
        React.js, GLSL, Express.js, Firebase, and Cloud Functions.
      </li>
      <li>
        Design and development of 3D solutions with Three.js and
        React-Three-Fiber.
      </li>
      <li>
        Recently developed a custom WordPress theme to enable blockchain connectivity,
        using PHP and JavaScript.
      </li>
    </ul>
  </div>

  <div className="mb-4">
    <h3 className="font-bold">Neon Caron Studio</h3>
    <p className="italic">
      General Producer - May 2017 - 2022, Mexico City
    </p>
    <ul className="list-square ml-5 font-light">
      <li>Design and development of prototypes for an ERC721 NFT collection, using Solidity for Smart Contracts and a JavaScript script for automated image generation, combining digital art with blockchain.</li>
      <li>Maintenance and enhancement of an Augmented Reality mobile app developed in Swift, ensuring a smooth and up-to-date user experience.</li>
      <li>
        Multidisciplinary artistic and technical production, combining
        traditional methods with advanced technologies like Augmented Reality.
      </li>
      <li>
        Web management and project development, including website maintenance and web solutions development for Augmented Reality.
      </li>
      <li>
        Creation of digital and traditional animation to enrich artistic works.
      </li>
      <li>
        Exhibitions at international fairs, collaborating with brands like
        Mercedes Benz to integrate art and technology innovatively.
      </li>
      <li>
        Innovation in production, using advanced production methods like CNC cutters and drawing robots.
      </li>
      <li>
        Leadership and project coordination, ensuring the exceedance of artistic and technical expectations.
      </li>
    </ul>
  </div>
</section>

<section className="mb-6">
  <h2 className="text-2xl font-jura mb-4">
    Highlighted Freelance Projects (2020 - Present)
  </h2>

  {/* Initial projects visible */}
  <div className="mb-4">
    <h3 className="font-bold">Lore Machine (Los Angeles, USA)</h3>
    <p className="font-light">
      Full Stack development of an application that uses generative AI
      for audiovisual production, employing Next.js, TypeScript, and
      PostgreSQL.
    </p>
  </div>

  <div className="mb-4">
    <h3 className="font-bold">custodia.place (Puebla, Mexico)</h3>
    <p className="font-light">
      Backend development for a digital artistic work on blockchain,
      using Solidity, Truffle, and web3.js, with an interface created in
      Handlebars.js.
    </p>
  </div>

  <div className="mb-4">
    <h3 className="font-bold">Gym Buddies (New York, USA)</h3>
    <p className="font-light">
      Leadership in the development of a web platform to connect the
      gym community, implementing Firebase Firestore and React with
      Tailwind CSS.
    </p>
  </div>

  {/* Continue adding your projects in the same way as the previous ones */}
  <div className="mb-4">
    <h3 className="font-bold">
      BARCO - Contemporary Art Biennial (Guanajuato, Mexico)
    </h3>
    <p className="font-light">
      Full Stack development of a digital platform for the biennial,
      including a custom WordPress theme and a web app with
      React, Firestore, and TailwindCSS.
    </p>
  </div>
  <div className="mb-4">
    <h3 className="font-bold">Divine Nymphs (Miami, USA)</h3>
    <p className="font-light">
      Development of an ERC-721 contract for NFTs, integrating alternate
      reality on blockchain with Solidity and Truffle Suite.
    </p>
  </div>

  {/* Button to show more projects */}
  {!verMas && (
    <button
      onClick={() => setVerMas(true)}
      className="px-4 py-2 border-2 border-white text-white hover:text-[#04080F] rounded hover:bg-white transition duration-300 w-full mt-2"
    >
      Show More +
    </button>
  )}

  {/* Additional projects shown when clicking "Show More +" */}
  {verMas && (
    <>
      <div className="mb-4">
        <h3 className="font-bold">hivemind DAO (Brazil)</h3>
        <p className="font-light">
          Developed smart contracts to strengthen the community
          of artists and collectors in the Web3 ecosystem, promoting
          creativity and digital commerce through Solidity and rigorous
          unit testing.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          aestheticMachine.js (Mexico City, Mexico)
        </h3>
        <p className="font-light">
          Led an artistic project that merges digital art and
          blockchain, allowing users to create graphics through
          p5.js and mint ERC-721 tokens, standing out in major cultural
          events.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          BILIA - Latin American Biennial of Artificial Intelligence
          (Mexico City, Mexico)
        </h3>
        <p className="font-light">
          Contributed with an installation that explores AI and art,
          using Machine Learning techniques to generate
          conceptual visualizations and foster dialogue between
          technology and artistic expression.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          Faculty of Arts and Design, UNAM (Mexico City, Mexico)
        </h3>
        <p className="font-light">
          Developed a metaverse as part of an academic project,
          offering a platform for the exhibition of digital art and
          promoting debate about perception in digital contexts.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">Cut Out Fest (Querétaro, Mexico)</h3>
        <p className="font-light">
          Developed a web app and a metaverse prototype for this
          international festival of animation and digital art,
          implementing a real-time multiplayer environment and a Solidity-based NFT system.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          Trámite, Buro de Coleccionistas - Digital Refuge (Querétaro,
          Mexico)
        </h3>
        <p className="font-light">
          Collaborated in creating a unique metaverse, transforming digital
          interaction into a tangible experience through hardware programming and 3D development.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          Netflix Latin America (Mexico City, Mexico)
        </h3>
        <p className="font-light">
          Contributed to the creation of immersive visual experiences
          through 360 video panoramas, using After Effects and HTC virtual reality viewers to optimize viewing and ensure flawless integration of images.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          Sony Music - Augmented Reality Filter (Mexico City,
          Mexico)
        </h3>
        <p className="font-light">
          Developed an augmented reality filter for Instagram with
          Spark AR and Maya, achieving significant success upon launch and demonstrating visual impact and audience engagement.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          ZeYX Lab (Mexico City - Querétaro, Mexico)
        </h3>
        <p className="font-light">
          At ZeYX Lab, a digital art and technology observatory,
          I led projects that explore the fusion of art, music, and
          digital technologies, highlighting:
        </p>
        <ul className="list-disc ml-5">
          <li className="font-light">
            <strong>The Obsidian Temple:</strong> Created a digital
            space for hypermedial narratives, integrating a Twitter
            bot that interacts with users, fostering interactive dialogue and collective reflections.
          </li>
          <li className="font-light">
            <strong>Outsiders Gallery:</strong> Developed a digital
            exhibition using THREE.js to present works of Mexican digital artists, offering an immersive and avant-garde experience.
          </li>
          <li className="font-light">
            <strong>Liberty Gallery - "Anamorphic Crystals":</strong> Participated in a live digital art event, using hydra-synth.js to create real-time anamorphic visuals, expanding the possibilities of visual art and artistic interaction.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">
          Otoño en Hiroshima (Mexico City, Mexico)
        </h3>
        <p className="font-light">
          Developed an interactive experience for the EP "Tar" by
          Gardens, using THREE.js to create a virtual environment that
          merges music and visuals, transforming the way music is digitally experienced and strengthening the connection between artists and audience.
        </p>
      </div>
    </>
  )}
</section>

<section className="pb-20">
  <h2 className="text-2xl font-jura mb-4">Education</h2>
  <div>
    <p>
      Bachelor's degree in Design and Visual Communication (UNAM) and specialized courses in Cloud, Data, Deep Learning and Blockchain, highlighting a continuous commitment to learning and technological innovation.
    </p>
  </div>
  {/* Add more education as needed */}
</section>



</>



      
  }


    </div>

  );
}

export default CV;
